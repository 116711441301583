@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);
@import url(https://fonts.googleapis.com/css?family=Orbitron);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@font-face {
  font-family: "NoirPro";
  src: local("NoirPro"), url(/static/media/noir-pro.f8060c77.ttf) format("truetype");
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

